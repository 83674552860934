$bomb-font-size: 28px;
$start-font-size: 64px;

.bomb-ticker {
  color: green;
  font-family: Orbitron;
  position: absolute;
  font-style: italic;
  left: 63px;
  top: 13px;
  width: 221px;
  height: 43px;
  font-size: $bomb-font-size;

  .odometer-block {
    width: 100%;
    padding: 0 12px;
    text-align: center;

    .hour > span,
    .min > span,
    .sec > span {
      display: inline-block;
      width: $bomb-font-size * 0.9;
      text-align: center;
    }
  }

  .odometer-block.finished {
    text-align: center;
    padding: 0;

    svg {
      margin: 0 10px;
      color: red;
    }
  }
}

.start-ticker {
  color: green;
  font-family: Orbitron;
  font-style: italic;
  font-size: $start-font-size;
  text-align: center;
  margin: auto;
  display: block;

  @media (max-width: 500px) {
    font-size: 32px;
  }

  &.odometer-block {
    .hour > span,
    .min > span,
    .sec > span {
      display: inline-block;
      width: $start-font-size * 0.9;

      @media (max-width: 500px) {
        width: 32px;
      }

      text-align: center;
    }
  }
}
