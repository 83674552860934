.odometerStart {
    display: inline-block;
}

.odometerEnd {
    display: inline-block;
}

.odometer-block {
    display: inline-block;
    justify-content: center;
    align-items: center;
}