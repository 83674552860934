@import '~bootswatch/dist/slate/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/slate/bootswatch';

@font-face {
  font-family: 'Orbitron';
  src: local('Orbitron'), url(./fonts/Orbitron-Regular.ttf) format('truetype');
  src: local('Orbitron-Bold'), url(./fonts/Orbitron-Bold.ttf) format('truetype');
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.navbar {
  @media (max-width: 767px) {
    border: none;
  }
}

.game-button {
  width: 44px;
  height: 44px;
  display: block;
  float: left;
  margin-right: 3px;
  background-image: url(/images/button-up.png);
  background-size: cover;
  bottom: 53px;
  font-size: 9px;
  padding-top: 7px;
  text-align: center;

  &.indicator-complete {
    img {
      // https://codepen.io/sosuke/pen/Pjoqqp
      filter: brightness(0) saturate(100%) invert(93%) sepia(31%) saturate(762%) hue-rotate(50deg) brightness(97%) contrast(93%);
    }

    a,
    svg {
      color: lightgreen;
    }
  }

  &.disabled {
    cursor: not-allowed;

    img,
    svg {
      opacity: 0.2;
    }
  }

  a {
    display: block;
    text-decoration: none;
  }

  a,
  div {
    width: 100%;
    height: 100%;
  }

  &.active {
    background-image: url(/images/button-down.png);
  }

  &.lock {
    padding-top: 6px;

    .indicator {
      top: -17px;
    }
  }

  .lock-sequence {
    margin-top: 6px;
    position: relative;

    img {
      width: 32px;
      height: 32px;
      margin-left: -8px;
    }

    .sequence {
      display: block;
      position: absolute;
      top: 0;
      right: 9px;
      font-size: 12px;
      font-family: 'Orbitron';
      top: 1px;
      left: 24px;
      opacity: 0.6;
    }
  }

  img {
    width: 44px;
    height: 44px;
    margin: -1px 0 0 0;
    padding: 0;
  }

  svg.bomb {
    width: 18px;
    height: 18px;
    margin: 3px 0 3px 0;
    padding: 0;
  }
}

$buttons-toprow-top: 478px;
$buttons-bottomrow-top: 522px;

.puzzle-buttons {
  position: absolute;
  text-align: center;
  top: $buttons-toprow-top;
  left: 33px;

  img.puzzle {
    fill: green;
  }
}

.puzzle-unlocked img {
  width: 250px;
  margin: auto;
  display: block;
}

.puzzle-unlocked .card-text {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.puzzle-icon.bomb {
  display: block;
  width: 250px;
  margin: 25px auto;
}

.puzzle-status.bomb {
  margin: auto;
  width: 60px;
  display: block;
  margin-bottom: 25px;
}

.game-puzzle-view {
  .puzzle {
    width: 250px;
    display: block;
    margin: 25px auto;
  }
}

.journal-list {
  font-size: 12px;

  .journal-message {
    img {
      margin-top: -1px;
      width: 10px;
      height: 10px;
    }
  }
}

.card-header.orbi-header {
  font-family: 'Orbitron';
  font-size: 24px;
}

.btn {
  font-family: 'Orbitron';
}

.orbi {
  font-family: 'Orbitron';
}

.admin-chat .chats {
  &.clue {
    padding: 10px;
    margin-bottom: 1rem;
  }

  background-color: green;
  color: lightgreen;
  height: 500px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0);

  video {
    width: 250px;
  }
}

.chats {
  &.clue {
    padding: 0;
  }

  .chat-body {
    .chat-message {
      color: black;
      border-radius: 10px;
      padding: 10px 15px;
      display: inline-block;
      position: relative;
      clear: both;

      .wrong-answer {
        color: red;
      }

      .chat-message__message-outer {
        white-space: pre-wrap;
      }

      &.from-me {
        margin-left: 20px;
        background-color: #00e34d;
        color: white;
        float: right;
        border-bottom-right-radius: 20px 0px\9;

        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: -2px;
          right: -8px;
          height: 19px;
          border-right: 20px solid #00e34d;
          border-bottom-left-radius: 16px 14px;
          -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: -2px;
          right: -42px;
          width: 12px;
          height: 20px;
          background: white;
          border-bottom-left-radius: 10px;
          -webkit-transform: translate(-30px, -2px);
          transform: translate(-30px, -2px);
        }
      }

      &.from-them {
        background-color: #e5e5ea;
        margin-right: 20px;
        float: left;
        border-bottom-left-radius: 20px 0px\9;

        &::before {
          content: '';
          position: absolute;
          z-index: 2;
          bottom: -2px;
          left: -7px;
          height: 19px;
          border-left: 20px solid #e5e5ea;
          border-bottom-right-radius: 16px 14px;
          -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
          border-bottom-right-radius: 15px 0px\9;
          transform: translate(-1px, -2px) \9;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 3;
          bottom: -2px;
          left: 4px;
          width: 26px;
          height: 20px;
          background: white;
          border-bottom-right-radius: 10px;
          -webkit-transform: translate(-30px, -2px);
          transform: translate(-30px, -2px);
        }
      }
    }
  }
}

@import './BombCountdown.scss';

.game-container {
  width: 340px;
  height: 571px;
  margin: 26px auto 0 auto;
  position: relative;

  @media (min-height: 850px) {
    margin-top: 80px;
    height: 735px;
  }
}

.game-lock-list {
  position: absolute;
  left: 101px;
  top: $buttons-bottomrow-top;
}

.game-lock {
  position: absolute;
  top: 77px;
  left: 13px;
  width: 313px;
  color: black;
  font-size: 14px;
  font-family: 'Orbitron';

  .clue {
    height: 338px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);
    padding: 5px;
    font-size: 16px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(255, 0, 0, 0.8);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: rgba(255, 0, 0, 0.4);
    }

    hr {
      border-top: 1px solid black;
    }

    .clue-heading {
      width: 100%;
      text-align: center;
    }

    .table {
      color: black;
    }

    &.clue-A1::before {
      background-image: url(/images/puzzles/A1.svg);
    }

    &.clue-A2::before {
      background-image: url(/images/puzzles/A2.svg);
    }

    &.clue-A3::before {
      background-image: url(/images/puzzles/A3.svg);
    }

    &.clue-A4::before {
      background-image: url(/images/puzzles/A4.svg);
    }

    &.clue-A5::before {
      background-image: url(/images/puzzles/A5.svg);
    }

    &::before {
      content: '';
      position: absolute;
      top: 16px;
      width: 100%;
      height: 313px;
      left: 0;
      opacity: 0.125;
      background-size: cover;
    }
  }

  .dock-bottom {
    height: 52px;
    margin-left: -8px;
    margin-right: -8px;
    margin-top: 10px;

    .submit-icon {
      position: absolute;
      right: -7px;
      top: 7px;
      padding: 5px 9px;
    }

    .lock-unlocked {
      color: black;
      text-align: center;
      width: 100%;
      height: 100%;
      font-size: 18px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-image: url(/images/correct.png);
      background-position: 0px 0px;
      background-size: cover;
    }

    .form {
      position: absolute;
      width: 100%;
      bottom: 0px;

      .form-control__lock {
        background-color: transparent;
        border: 0;
        color: black;
        padding: 0 0 0 5px;
        margin-left: 5px;
        float: left;

        &.loading {
          cursor: wait;
          color: gray;

          &::placeholder {
            color: gray;
          }
        }

        &::placeholder {
          color: black;
        }

        &:focus {
          background-color: transparent;
          border: 0;
          box-shadow: none;
        }

        &.wrong {
          animation: wronganswer normal 1.5s linear;
          background-image: url(/images/wrong.png);
          background-position: 0px 0px;
          background-size: cover;
          width: 328px;
          height: 52px;
          margin-left: 0px;
          padding-left: 12px;
        }

        @keyframes wronganswer {
          0% {
            opacity: 0;
          }
          59% {
            opacity: 1;
          }
          100% {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

video {
  width: 100%;
  cursor: pointer;
}

.no-team {
  .btn {
    width: 100%;
    font-size: 24px;
  }

  @media (max-width: 767px) {
    .new-team {
      margin-bottom: 1rem !important;
    }
  }
}

svg.loading {
  display: none;
}

.loading {
  svg.loading {
    display: inline-block;
  }
  svg.fa-chevron-right {
    display: none;
  }
}

.btn[disabled] {
  cursor: wait;
}

.bomb-panel {
  position: relative;
  background-image: url(/images/bomb-panel.jpg);
  background-position-x: calc(50% + 5px);
  background-position-y: -54px;
  background-repeat: no-repeat;
  width: 1268px;
  margin: auto;

  &.bomb-panel-open {
    background-image: url(/images/bomb-open.jpg);
  }

  @media (min-height: 850px) {
    background-position-y: unset;
    height: 831px;
  }
}

.table-dashboard {
  &.small-images {
    img {
      width: 32px;
      height: 32px;
      margin-bottom: -11px;
    }
  }

  tr:hover {
    td {
      background-color: RGB(163, 255, 163, 0.1);
    }
  }
}

tr.current-team,
tr.current-lock {
  td {
    background-color: RGB(255, 255, 163, 0.1);
  }
}

.admin-dashboard {
  .extra-info {
    font-size: 10px;
  }

  td.unread-chat {
    background-color: RGB(255, 0, 0, 1) !important;
    opacity: 1;
  }

  td.not-activated {
    color: rgb(92, 92, 92);
  }

  td.current-page {
    border: 2px solid white;
  }

  td {
    text-align: center;

    &.team-name {
      text-align: left;
    }
  }

  td.lock {
    opacity: 0.6;

    &.lock-selected {
      opacity: 1;
    }
  }

  td.lock,
  th.lock {
    text-align: center;

    &.lock-A1 {
      background-color: orange;
    }

    &.lock-A2 {
      background-color: red;
    }

    &.lock-A3 {
      background-color: green;
    }

    &.lock-A4 {
      background-color: blue;
    }

    &.lock-A5 {
      background-color: purple;
    }

    &.lock-BOMB {
      background-color: lightsalmon;
    }
  }

  th {
    height: 175px;
    overflow: hidden;
    padding-bottom: 10px;

    span {
      transform: rotate(-90deg);
      white-space: nowrap;
      display: inline-block;
      padding: 0;
      margin: 0;
      width: 25px;
    }
  }

  .bomb-ticker {
    position: static;
    font-size: 72px;
    line-height: 72px;
    height: auto;
    width: auto;

    .odometer-block {
      .hour > span,
      .min > span,
      .sec > span {
        width: 64px;
      }
    }
  }
}

.chat-snippets {
  position: absolute;
  top: 680px;

  td {
    text-align: left;

    cursor: pointer;

    &.already-sent {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.chat-container {
  font: 400 15px 'Open Sans', sans-serif;
  position: absolute;
  top: 5px;
  right: 70px;
  width: 300px;
  height: 605px;
  background-image: url(/images/iphone.png);
  background-size: cover;
  padding: 69px 17px 10px 18px;

  @media (min-height: 850px) {
    top: 58px;
  }

  .chats {
    height: 376px;

    &.reduced-height {
      height: 316px;
    }

    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    padding: 10px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(37, 234, 64, 0.8);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: rgba(37, 234, 64, 0.4);
    }
  }

  .chat-waiting {
    cursor: pointer;
    background-color: red;
    margin: 0 0 0 1px;
    padding: 8px 10px;
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: 21px;

    animation: color-change 2s infinite;

    @keyframes color-change {
      0% {
        background-color: red;
      }
      50% {
        background-color: orange;
      }
      100% {
        background-color: red;
      }
    }
  }

  .chat-header {
    background-color: RGB(255, 255, 163, 0.1);
    margin: 0px -10px;
    padding: 8px 10px;
    overflow-x: hidden;

    .clue-heading {
      width: 100%;
      text-align: center;
    }
  }

  .dock-bottom {
    background-color: white;
    border-top: 1px solid #aaa;
    margin: -10px 0;
    padding: 8px 6px;
  }

  .chat-home {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 125px;
    bottom: 13px;
    cursor: pointer;
  }

  .form-control {
    background-color: #eeeeee;
    color: black;
    border: none;
    padding: 0.25rem 0.5rem;
    width: 210px;
    height: 38px;
  }

  button {
    float: right;
    margin-top: -39px;
    margin-right: -1px;
    padding: 8px 10px;
    border-radius: 15px;
  }
}

tr.is-clickable {
  &:hover {
    th,
    td {
      background-color: RGB(163, 255, 163, 0.1);
    }
  }

  th,
  td {
    cursor: pointer;
  }
}

.admin-team-edit {
  .example-email {
    font-family: Arial, Helvetica, sans-serif;

    table {
      td {
        padding-bottom: 25px;
      }
      td.label {
        vertical-align: top;
        padding-right: 25px;
        font-weight: 700;
      }
    }
  }
}
